import axios from "axios";
import { store } from "../store/store";
axios.interceptors.request.use(
  function(config) {
    const token = store.getters.getToken;
    if (token && !config.url.includes(process.env.VUE_APP_API_WOMPI_VERIFI)) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.log(error);
    return Promise.reject(error);
  }
);
