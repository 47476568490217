import axios from "axios";

export default class ProductosService {
  getAll(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "productos/all")
  }
  getDisponibles(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "productos/enabled")
  }
  getById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "productos/"+id)
  }
  createProducto(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "productos",data)
  }
  deleteProducto(id){
    const data={
      id
    }
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "productos",{data})
  }
  editProducto(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "productos/edit",data)
  }
  getFullById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "productos/full/"+id)
  }
  
}
