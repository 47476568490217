import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { store } from "@/store/store";

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("@/components/main.vue"),
    redirect: { name: "Home" },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/components/PublicComponents/Home.vue"),
        meta: { esPublico: true },
      },
      {
        path: "/blog",
        name: "blog",
        component: () => import("@/components/SeccionDeArticulos"),
        meta: { esPublico: true },
      },
      {
        path: "/blog/detalle_articulo/:id",
        name: "detalle_articulo",
        component: () => import("@/components/DetalleArticulo"),
        meta: { esPublico: true },
      },  
      {
        path: "registrate",
        name: "Registrate",
        component: () => import("@/components/PublicComponents/Registrate.vue"),
        meta: { esPublico: true, soloCuandoNoEsteLogueado: true },
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("@/components/PublicComponents/Profile.vue"),
      },
      /* {
        path: "unsuscribe/:id",
        name: "Unsuscribe",
        component: () =>
          import("@/components/PublicComponents/UnsuscribeNewsletter.vue"),
        meta: { esPublico: true },
      }, */
      {
        path: "unsuscribe/:id",
        name: "Unsuscribe",
        component: () =>
          import("@/components/PublicComponents/UnsuscribeNewsletterMail.vue"),
        meta: { esPublico: true },
      },
      {
        path: "quienessomos",
        name: "QuienesSomos",
        component: () =>
          import("@/components/PublicComponents/QuienesSomos.vue"),
        meta: { esPublico: true },
      },
      {
        path: "sesiones/:sesion",
        name: "Sesiones",
        component: () =>
          import("@/components/PublicComponents/SesionDetail.vue"),
        meta: { esPublico: true },
      },
      /* {
        path:'productos',
        name:'Productos',
        component: () => import("@/components/PublicComponents/Productos.vue"),
        meta: { esPublico: true },
      },
      {
        path:'shoppingcar',
        name:'ShoppingCar',
        component: () => import("@/components/PublicComponents/ShoppingCar.vue"),    
        meta: { esPublico: true },    
      }, */

      {
        path: "contactanos",
        name: "Contactanos",
        component: () =>
          import("@/components/PublicComponents/Contactanos.vue"),
        meta: { esPublico: true },
      },
      /* {
        path:'checkout',
        name:'Checkout',
        component: () => import("@/components/PublicComponents/Checkout.vue"),
        meta: { productosEnCarrito: true },        
      },
      {
        path:'mis-pedidos',
        name:'MisPedidos',
        component: () => import("@/components/PublicComponents/MisPedidos.vue")
      },
      {
        path:'check-status',
        name:'CheckStatus',
        component: () => import("@/components/PublicComponents/CheckStatus.vue"),
        meta: { esPublico: true },    
      } */
      {
        path: "agendamientos-user",
        name: "AgendamientosUser",
        component: () => import("@/components/PublicComponents/AgendamientosListClient.vue"),
      },
      {
        path: "agendamiento-detalle/:id",
        name: "DetalleAgendamientoCliente",
        component: () => import("@/components/PublicComponents/DetalleAgendamientoCliente.vue"),
      },      
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/components/AdminComponents/mainAdmin.vue"),
    redirect: { name: "HomeAdmin" },
    meta: { tipoUsuarioSinAcceso: ["public"] },
    children: [
      {
        path: "home-admin",
        name: "HomeAdmin",
        component: () => import("@/components/AdminComponents/HomeAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "users-list",
        name: "UsersList",
        component: () =>
          import("@/components/AdminComponents/UsersListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "users-process/:id?",
        name: "UsersProcessAdmin",
        component: () =>
          import("@/components/AdminComponents/UsersProcessAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "categorias-list",
        name: "CategoriasList",
        component: () =>
          import("@/components/AdminComponents/CategoriasListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "categorias-process/:id?",
        name: "CategoriasProcessAdmin",
        component: () =>
          import("@/components/AdminComponents/CategoriasProcessAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "subcategorias-list",
        name: "SubcategoriasList",
        component: () =>
          import("@/components/AdminComponents/SubcategoriasListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "subcategorias-process/:id?",
        name: "SubcategoriasProcessAdmin",
        component: () =>
          import("@/components/AdminComponents/SubcategoriasProcessAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "productos-list",
        name: "ProductosList",
        component: () =>
          import("@/components/AdminComponents/ProductosListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "productos-process/:id?",
        name: "ProductosProcessAdmin",
        component: () =>
          import("@/components/AdminComponents/ProductosProcessAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "presentaciones-list",
        name: "PresentacionesList",
        component: () =>
          import("@/components/AdminComponents/PresentacionesListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "presentaciones-process/:id?",
        name: "PresentacionesProcessAdmin",
        component: () =>
          import("@/components/AdminComponents/PresentacionesProcessAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "producto-presentaciones-admin/:id?",
        name: "ProductoPresentacionesProcessAdmin",
        component: () =>
          import(
            "@/components/AdminComponents/ProductoPresentacionesProcessAdmin.vue"
          ),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "calendario-generator",
        name: "CalendarioGenerator",
        component: () =>
          import("@/components/AdminComponents/CalendarioAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "calendario-trabajo",
        name: "CalendarioDeTrabajo",
        component: () =>
          import("@/components/AdminComponents/CalendarOfWork.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "pedidos-admin",
        name: "PedidosAdmin",
        component: () =>
          import("@/components/AdminComponents/ListadoDePedidos.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "pedidos-mapa",
        name: "MapaDePedidos",
        component: () =>
          import("@/components/AdminComponents/MapaDePedidos.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "agendamientos-admin",
        name: "AgendamientosAdmin",
        component: () =>
          import("@/components/AdminComponents/AgendamientosListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "detalle-agendamiento-admin/:id",
        name: "DetalleAgendamiento",
        component: () =>
          import("@/components/AdminComponents/DetalleAgendamiento.vue"),
        meta: { tipoUsuarioSinAcceso: ["public"] },
      },
      {
        path: "articulos-list-admin",
        name: "ArticulosAdmin",
        component: () =>
          import("@/components/AdminComponents/ArticulosListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public","admin"] },
      },
      {
        path: "articulos-create-admin/:id?",
        name: "CreateArticuloProcessAdmin",
        component: () =>
          import("@/components/AdminComponents/CreateArticuloAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public","admin"] },
      },
      {
        path: "newsletter-list-admin",
        name: "NewsLetterAdmin",
        component: () =>
          import("@/components/AdminComponents/NewLetterListAdmin.vue"),
        meta: { tipoUsuarioSinAcceso: ["public","admin"] },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length == 0) {
    return next({ name: "Home" });
  }

  const esPublico = to.matched.some((record) => record.meta.esPublico);
  const productosEnCarrito = to.matched.some(
    (record) => record.meta.productosEnCarrito
  );
  const soloCuandoNoEsteLogueado = to.matched.some(
    (record) => record.meta.soloCuandoNoEsteLogueado
  );
  const dataUser = store.getters.getUserData;
  const cantidadProductosEnCarrito = store.getters.getItemInCar;
  const token = dataUser.token;
  const tipoUsuarioLogueado = dataUser.role;

  if (!esPublico && !token) {
    return next({ name: "Home" });
  }

  if (token && soloCuandoNoEsteLogueado) {
    return next({ name: "Home" });
  }

  const tiposDeUsuarioSinAcceso = to.matched[0].meta.tipoUsuarioSinAcceso
    ? to.matched[0].meta.tipoUsuarioSinAcceso
    : [];

  if (tiposDeUsuarioSinAcceso.length > 0 && tipoUsuarioLogueado != null) {
    if (tiposDeUsuarioSinAcceso.indexOf(tipoUsuarioLogueado) != -1) {
      return next({ name: "Home" });
    }
  }

  if (productosEnCarrito && cantidadProductosEnCarrito == 0) {
    return next({ name: "Home" });
  }

  next();
});

export default router;
