export const getters = {
  getUserData(state) {
    return state.datauser;
  },
  getToken(state) {
    return state.datauser.token;
  },
  getRoleUser(state) {
    return state.datauser.role;
  },
  getNombreUser(state) {
    return `${state.datauser.nombres} ${state.datauser.apellidos}`;
  },
  getTelefonoUser(state) {
    return state.datauser.telefono;
  },
  getItemInCar(state) {
    return state.carrito.length;
  },
  getAllItemInCar(state) {
    return [...state.carrito];
  },
  getIsPlaying(state) {
    return state.isPlaying;
  },
};
