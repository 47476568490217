<template>
  <b-modal
    id="modal-detail-producto"
    ref="modal-detail-producto"
    size="xl"
    :title="producto ? producto.nombre.toUpperCase() : ''"
    scrollable
    header-bg-variant="primary"
    header-text-variant="light"
    footer-bg-variant="gray"
    @hidden="ocultado()"
    hide-footer
  >
    <div v-if="producto">
      <p>{{ producto.categoria | laPrimeraMayuscula }} / {{ producto.subcategoria | laPrimeraMayuscula }}</p>
      <div class="card-propia-image mb-3">
        <img
          :src="`${urlImagesProductos}${producto.imagen}`"
          :alt="producto.nombre"
          @click="mostrarDetalle(producto.id)"
        />
      </div>
      <div class="info-container" v-if="producto.descripcion.trim().length > 0">
        <div class="title-container">
          <span> Descripcion </span>
        </div>
        <div class="data-container">
          {{ producto.descripcion | laPrimeraMayuscula }}
        </div>
      </div>
      <div
        class="info-container"
        v-if="producto.ingredientes.trim().length > 0"
      >
        <div class="title-container">
          <span> Ingredientes </span>
        </div>
        <div class="data-container">
          {{ producto.ingredientes | laPrimeraMayuscula }}
        </div>
      </div>
      <div class="info-container" v-if="producto.consumo.trim().length > 0">
        <div class="title-container">
          <span> Modo de Consumir </span>
        </div>
        <div class="data-container">
          {{ producto.consumo | laPrimeraMayuscula }}
        </div>
      </div>
      <div class="info-container" v-if="producto.presentaciones.length > 0">
        <div class="title-container">
          <span> Presentaciones y precios </span>
        </div>
        <div class="data-container">
          <ul>
            <li
              v-for="(presentacion, index) in producto.presentaciones"
              :key="index"
            >
              <span style="font-style: italic; font-weight: 600">{{
                presentacion.presentacion
              }}</span>
              - {{ presentacion.precio | numero }} $
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="container-footer">
        <div class="container-total"></div>
        <div class="container-botones">
          <b-button variant="secondary" size="sm" @click="cancel()">
            Cerrar
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ProductosService from "@/services/productos.service.js";
import EventBus from "@/services/eventBus.js";
export default {
  name: "ModalAddProduct",
  props: {
    show: Boolean,
    idProduct: Number,
  },
  data() {
    return {
      productosService: null,
      urlImagesProductos: process.env.VUE_APP_API_IMAGES_PRODUCTOS,
      producto: null,
    };
  },
  created() {
    this.productosService = new ProductosService();
  },
  destroyed() {
    console.log("destruido ");
  },
  methods: {
    getProduct() {
      this.productosService
        .getFullById(this.idProduct)
        .then((result) => {
          this.producto = null;
          if (result.data.data) {
            this.producto = { ...result.data.data };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancel() {
      EventBus.$emit("SHOW_MODAL_DETAIL", {
        idProductoInDetail: 0,
        showModalDetail: false,
      });
    },
    ocultado() {
      EventBus.$emit("SHOW_MODAL_DETAIL", {
        idProductoInDetail: 0,
        showModalDetail: false,
      });
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.$bvModal.show("modal-detail-producto");
        this.getProduct();
      } else {
        this.$bvModal.hide("modal-detail-producto");
      }
    },
  },
};
</script>

<style>
.container-imagen {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.container-footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.container-total {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-start;
}
.container-botones {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: flex-end;
}
.title-container {
  font-size: 0.9rem;
  font-weight: 700;
}

.info-container {
  border: 1px solid darkgrey;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 8px;
}
.card-propia-image {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  height: 300px;
  overflow: hidden;
  align-content: center;
  margin:0px auto
}

.card-propia-image img {
  width: auto;
    height: 100%;
}
@media (max-width: 600px) {
  .container-imagen {
    width: 75px;
    height: 75px;
    overflow: hidden;
    border-radius: 50%;
  }
  .container-total,
  .container-botones {
    width: 100%;
    justify-content: space-around;
  }
}
</style>
