const getDefaultState = () => {
  return {
    datauser: {
      identificacion: "",
      apellidos: "",
      email: "",
      id: 0,
      nombres: "",
      role: "",
      telefono: "",
      token: null,
      newsletter: false,
    },
  };
};

export const mutations = {
  resetState(state) {
    //reset del state
    Object.assign(state, getDefaultState());
  },
  setUserData(state, payload) {
    //reset del state
    state.datauser = { ...payload };
  },
  logout(state) {
    state.datauser = { ...getDefaultState().datauser };
  },
  setProductInCar(state, payload) {
    state.carrito.push(payload);
  },
  deleteProductInCar(state, internalCode) {
    state.carrito = state.carrito.filter((item) => {
      return item.internalCode != internalCode;
    });
  },
  changeCantidadProductInCar(state, payload) {
    const { cantidad, internalCode } = payload;
    for (let index = 0; index < state.carrito.length; index++) {
      if (state.carrito[index].internalCode === internalCode) {
        state.carrito[index].cantidad = cantidad;
      }
    }
  },
  updateDataUser(state, payload) {
    state.datauser.identificacion = payload.identificacion;
    state.datauser.nombres = payload.nombres;
    state.datauser.apellidos = payload.apellidos;
    state.datauser.telefono = payload.telefono;
    state.datauser.nacimiento = payload.nacimiento;
    state.datauser.newsletter = payload.newsletter;
  },
  changeAdicionesProductInCar(state, payload) {
    const { adiciones, internalCode } = payload;
    for (let index = 0; index < state.carrito.length; index++) {
      if (state.carrito[index].internalCode === internalCode) {
        state.carrito[index].adiciones = adiciones;
      }
    }
  },
  cleanCarrito(state) {
    state.carrito = [];
  },
  setIsPlaying(state, payload) {
    state.isPlaying = payload;
  },
};
