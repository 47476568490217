<template>
  <div id="app">
    <!-- <mantenimiento /> -->
    <router-view />
    <b-modal id="modal-question" ref="modal-question" :title="payloadModal ? payloadModal.titleModal : ''" centered
      @show="resetModal" @hidden="resetModal" @ok="handleOk" :ok-title="$t('messages.accept')"
      :cancel-title="$t('messages.cancel')">
      {{ payloadModal ? payloadModal.messageModal : "" }}
    </b-modal>
    <modal-detail-product :show="showModalDetail" :idProduct="idProductoInDetail" />
  </div>
</template>

<script>
import EventBus from "@/services/eventBus.js";
import ModalDetailProduct from "@/components/PublicComponents/ModalDetailProduct.vue";
import Mantenimiento from "@/components/Mantenimiento.vue";
export default {
  components: { "modal-detail-product": ModalDetailProduct,"mantenimiento":Mantenimiento },
  data() {
    return {
      payloadModal: null,
      idProductoInDetail: 0,
      showModalDetail: false,
    };
  },
  mounted() {
    EventBus.$on("MOSTRAR_TOAST", (payLoad) => {
      this.$bvToast.toast(payLoad.message, {
        title: payLoad.title,
        variant: payLoad.variant,
        solid: true,
      });
    });
    EventBus.$on("SHOW_MODAL_QUESTION", (payLoad) => {
      this.payloadModal = { ...payLoad };
      this.$nextTick(() => {
        this.$bvModal.show("modal-question");
      });
    });
    EventBus.$on("SHOW_MODAL_DETAIL", (payLoad) => {
      this.idProductoInDetail = payLoad.idProductoInDetail;
      this.showModalDetail = payLoad.showModalDetail;
    });

    let language = navigator.language;
    if (language == "es-ES") {
      this.$i18n.locale = "es";
      this.$moment.locale('es')
    } else {
      this.$moment.locale('en')
      this.$i18n.locale = "en";
    }
  },
  methods: {
    resetModal() { },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      EventBus.$emit("RESPONSE_MODAL_QUESTION", {
        action: this.payloadModal.action,
        from: this.payloadModal.from,
      });
      this.$nextTick(() => {
        this.$bvModal.hide("modal-question");
      });
    },
  },
  watch: {
    '$i18n.locale': function (newVal) {
      this.$moment.locale(newVal)
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
}

.b-time-header {
  display: none !important;
}

.title-vista {
  font-size: 3.5rem;
}


@media (max-width: 376px) {

  .b-time-hours,
  .b-time-minutes {
    width: 38px !important;
  }

  .b-time {
    min-width: 120px !important;
  }
}
</style>
