import Vue from "vue";
import Vuex from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_API_STORE,
      paths: ["datauser", "carrito"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    datauser: {
      apellidos: "",
      email: "",
      id: 0,
      identificacion: "",
      nombres: "",
      role: "",
      telefono: "",
      token: null,
      newsletter: false,
    },
    carrito: [],
    isPlaying: false,
  },
  mutations,
  actions,
  getters,
});
