<template>
    <div class="header-container">
        <div class="logo-header-container">
            <div class="container-image" @click="$router.push({ name: 'Home' }).catch(() => { })" style="cursor: pointer">
                <img src="@/assets/logo_misty_oracle.jpg" alt="logo-misty-oracle" class="fijo-image" />
                <span class="logoTitle">Misty Oracle</span>
            </div>
        </div>
        <div class="message-container">
            <span>{{ $t("messages.maintenanceMessage") }}</span>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Mantenimiento",
};
</script>
  
<style lang="scss" scoped>
/* cabecera */
.header-container {
    height: 90px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    //border-bottom: 1px solid var(--primary-misty-oracle);
    /* box-shadow: 0 1px 5px rgb(0 0 0 / 10%); */
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.message-container {
    display: flex;
    padding-left: 30px;
    width: 80%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.fijo {
    position: sticky;
    top: 50px;
}

.logo-header-container,
.menu-header-container,
.avatar-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: white;
}

.logo-header-container {
    padding-left: 30px;
    width: 20%;
    height: 100%;
    justify-content: center;
}

.logo-image {
    height: 150px;
    z-index: 999;
    margin-top: 13px;
    transition: 0.5s all;
}

.fijo-image {
    height: 100%;
    z-index: 1;
    margin-top: 0px;
}

.menu-header-container {
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.ul-menu {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
}

.item-menu {
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;
    list-style: none;
    font-size: 1.2rem;
    padding: 0px 20px;
    cursor: pointer;
    height: 100%;
    align-items: center;
    transition: 0.5s color;
    color: var(--color-font-misty-oracle);
    letter-spacing: 3px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

.item-menu:hover {
    color: var(--primary-misty-oracle);
    border-bottom: 4px solid var(--primary-misty-oracle);
    padding: 4px 20px 0px 20px;
}

.avatar-container {
    width: 20%;
    height: 100%;
    justify-content: flex-end;
    padding-right: 20px;
}

/* menu lateral */
.ul-menu-lateral {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.ul-menu-lateral {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
}

.boton-menu-lateral {
    display: none;
}

.menu-lateral-public {
    width: 30px !important;
}

.item-menu-lateral {
    list-style: none;
    font-weight: 600;
    padding: 0.375rem 0.75rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;

    div {
        border-bottom: 1px solid #b3b3b3;
    }
}

.item-menu-lateral-extra {
    font-weight: 600;
    border-bottom: 1px solid #b3b3b3;
    color: var(--color-font-misty-oracle);
}

.item-menu-lateral:hover,
.item-menu-lateral-extra:hover {
    background-color: var(--background-misty-oracle);
}

.big {
    margin-top: -11px;
}

.shop-car-icon {
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

.container-image {
    /* padding: 30px; */
    /* z-index: 9999; */
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    top: 0px;
    left: 150px;
    height: 200px;
    width: 200px; */
    /* box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1); */
    height: 100%;
}

.container-image-fijo {
    /* padding: 0px;
    position: absolute;
    top: 0px;
    left: 100px;
    height: 100%;
    width: auto; */
    /* box-shadow: 0 7px 21px rgba(0, 0, 0, 0.1); */
}

.logoTitle {
    font-family: "Christmas-Day", cursive;
    font-size: 1.9rem;
}

@media (max-width: 1475px) {
    .logoTitle {
        font-size: 1rem;
    }
}

@media (max-width: 1370px) {
    .item-menu {
        font-size: 16px;
        letter-spacing: 0px;
    }
}

@media (max-width: 1100px) {
    .message-container{
        min-height: 300px;
    }
    .ul-menu {
        display: none;
    }

    .boton-menu-lateral {
        display: block;
    }

    .logo-header-container {
        width: 40%;
        padding: 0px;
    }

    .avatar-container {
        width: 40%;
        padding: 0px;
    }

    .menu-header-container {
        width: 20%;
    }
}

@media (max-width: 733px) {
    .logo-image {
        height: 80%;
        z-index: 1;
        margin-top: 0px;
        transition: 0.5s all;
    }
}

@media (max-width: 640px) {
    .container-image {
        padding: 10px;
        position: relative;
        height: 100%;
        left: 0;
        z-index: 1;
        box-shadow: none;
    }

    /* .container-image-fijo {
      left: 10px;
      z-index: 1;
    } */
}</style>