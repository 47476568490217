import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store/store";
import VueHtmlToPaper from "vue-html-to-paper";
Vue.use(VueHtmlToPaper);

import i18n from "./i18n";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.$cookies.config("30d");

/* bootstrap */
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "./assets/app.scss";
/* import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css"; */



// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import VueMoment from "vue-moment";
import moment from "moment-timezone";
moment.tz.setDefault("America/Bogota");
moment.locale("es");
Vue.use(VueMoment, { moment });

Vue.filter("todasLasPrimerasMayusculas", function (cadena) {

  const words = cadena.toLowerCase().split(' ');

  const capitalizedWords = words.map(word => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word;
    }
  });
  
  const capitalizedText = capitalizedWords.join(' ');

  return capitalizedText;  
});

Vue.filter("numero", function (numero) {
  return new Intl.NumberFormat("de-DE").format(numero);
});

Vue.filter("fecha", function (fecha) {
  return moment(fecha).format("DD-MM-yyyy");
});

Vue.filter("fechaHora", function (fecha) {
  return moment(fecha).format("DD-MM-yyyy hh:mm:ss");
});

Vue.filter("laPrimeraMayuscula", function (str) {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
});

import Axios from "axios";
import "./services/axiosInterceptor";
Vue.prototype.$Axios = Axios;

Vue.config.productionTip = false;

import VCalendar from "v-calendar";
Vue.use(VCalendar, {});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
